import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ReactTable from "react-table";

import 'react-table/react-table.css'
import './subpage.css'

import Layout from '../components/layout'
import PromoBlock from '../components/promoBlock'



export default ({ data }) => {
  console.log(data);
  const customOptionsFilter = ({filter, onChange}) => {
    var unique = [];
    return(
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        <div className="mobile-show">Category Filter:</div>
        <select
          onChange={e => onChange(e.target.value)}
          style={{  width: '100%'  }}
          value={filter ? filter.value : 'all'}
          >
          <option value='all'>Show All</option>
          {
            data.allAgenciesJson.edges[0].node.Agencies.map(( agency , index) => {
              if(!unique.includes(agency.category)) {
                  unique.push(agency.category);
                  return <option key={agency.category} value={agency.category}>{agency.category}</option>
              }
              return null
            })
          }
        </select>
      </div>
    )
  }
  const columns = [{
    expander: true,
    show: false
  },{
    Header: '',
    accessor: 'logo',
    width: 'auto',
    Cell: props => (
      props.value != null &&
        <div style={{width: '100%'}}><img src={props.value} alt="agency logo" style={{
          maxHeight: 50,
          background: '#ddd',
          borderRadius: 5,
          padding: 10,
          display: 'block',
          margin: '0 auto'
        }}/></div>
    )
  }, {
    Header: 'Agency',
    accessor: 'node',
    width: 'auto',
    Cell: props => (
      props.original.featured ? (
        <div>
          <span role="img" aria-label="star">⭐️</span> 
          <a href={props.original.link+"?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{props.original.name}</a> 
          <span role="img" aria-label="star">⭐️</span>
        </div>
      ) : (
        <a href={props.original.link+"?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{props.original.name}</a>
      )
    )
  }, {
    Header: 'Category',
    accessor: 'category',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show">Category: </strong>{props.value}</span>
    ),
    filterable: true,
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }) => customOptionsFilter({filter, onChange})
  }, {
    Header: 'Speciality',
    accessor: 'speciality',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show">Speciality: </strong>{props.value}</span>
    )
  }, {
    Header: '',
    id: 'isFeatured',
    accessor: 'featured',
    show: false
  }]
  return (
    <Layout>
      
      <Helmet>
        <title>AlphaDex | The Best Amazon Agencies</title>
        <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. These are the best agencies to help you with your specific amazon store needs." />
        <meta name="keywords" content="amazon, selling, agencies" />
      </Helmet>

      <div>
        <Link to={'/'}>Back</Link>
        <h1>Alphadex preferred agencies</h1>
        <ReactTable
          data={data.allAgenciesJson.edges[0].node.Agencies}
          columns={columns}
          showPagination={false}
          sortable={false}
          sorted={[
            {
              id: 'isFeatured',
              desc: true
            }
          ]}
          minRows={0}
          resizable={false}
          SubComponent={row => {
            console.log(row.row)
            return (
              row.row._original.isFeatured &&
                <PromoBlock featuredText={row.row._original.promo.featuredText} featuredImage={row.row._original.promo.featuredImage} link={row.row._original.link} />
            );
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return{
              'data-qnt': rowInfo.original.featured,
            }
          }}
        />

      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allAgenciesJson {
      edges {
        node {
          Agencies{
            name
            logo
            link
            category
            speciality
            featured
            promo{
              featuredImage
            }
          }
        }
      }
    }
  }
`